@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @media (max-width: 767px) { /* Tailwind's medium screensize is 768px */
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

/* index.css */
#netlify-identity-widget {
  display: none !important;
}

#netlify-identity-widget[style*="display: block"] {
  display: block !important;
}
